import { render, staticRenderFns } from "./Limites.vue?vue&type=template&id=f1ffe5ec&scoped=true&"
import script from "./Limites.vue?vue&type=script&lang=js&"
export * from "./Limites.vue?vue&type=script&lang=js&"
import style0 from "./Limites.vue?vue&type=style&index=0&id=f1ffe5ec&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1ffe5ec",
  null
  
)

export default component.exports