<template>
  <div class="wrapper">
      <!-- <div>
          <img src="@/assets/images/nosotros/background_illustration_NOSOTROS.svg" alt="">
      </div> -->
      <div class="content">
          <h1 style="text-transform:uppercase;">Límites Máximos Permisibles de <br>Emisiones Vehiculares Contaminantes</h1>
          <div class="tip">
              <div class="header">
                  <img src="@/assets/images/tips/num1.svg" alt="">
                  <div>VEHICULOS A GASOLINA | NOM 041 SEMARNAT 2015</div>
              </div>
             <h2>Tabla 1: <strong> Límites Máximos de Emisión del Método Dinámico</strong></h2>
              <div class="box">
                  <table>
                      <tr>
                        <th>Año -  <br> modelo vehicular</th>
                        <th>Hidrocarburos <br> (HC hppm)</th>
                        <th>Monóxido de Carbono <br> (CO % vol.)</th>
                        <th>Oxígeno <br> (O2 % vol.)</th>
                        <th>Óxidos de Nitrógeno <br> (NOx ppm)</th>
                        <th>Dilución <br>(CO + CO2 % vol.)</th>
                        <th>Factor Lambda Máx.</th>
                      </tr>
                      <tr>
                         <td>1993 y Anteriores</td>
                         <td>350</td>
                         <td>2.5</td>
                         <td>2.0</td>
                         <td>2,000</td>
                         <td>13 min. a 16.5 máx.</td>
                         <td>1.05</td>
                      </tr>
                      <tr>
                         <td>1994 a 2005</td>
                         <td>100</td>
                         <td>0.7</td>
                         <td>2.0</td>
                         <td>700</td>
                         <td>13 min. a 16.5 máx.</td>
                         <td>1.05</td>
                      </tr>
                  </table>
              </div>
                <h2>Tabla 2: <strong>  Límites Máximos de Emisión del Método Estático</strong></h2>
              <div class="box">
                  <table>
                      <tr>
                        <th>Año -  <br> modelo vehicular</th>
                        <th>Hidrocarburos <br> (HC hppm)</th>
                        <th>Monóxido de Carbono <br> (CO % vol.)</th>
                        <th>Oxígeno <br> (O2 % vol.)</th>
                        <th>Dilución <br>(CO + CO2 % vol.)</th>
                        <th>Factor Lambda Máx.</th>
                      </tr>
                      <tr>
                         <td>1993 y Anteriores</td>
                         <td>400</td>
                         <td>3.0</td>
                         <td>2.0</td>
                         <td>13 min. a 16.5 máx.</td>
                         <td>1.05</td>
                      </tr>
                      <tr>
                         <td>1994 a 2005</td>
                         <td>100</td>
                         <td>0.5</td>
                         <td>2.0</td>
                         <td>13 min. a 16.5 máx.</td>
                         <td>1.05</td>
                      </tr>
                  </table>
              </div>
          </div>
          <div class="tip">
              <div class="header">
                  <img src="@/assets/images/tips/num2.svg" alt="">
                  <div>VEHICULOS A DIESEL | NOM 045 SEMARNAT 2017</div>
              </div>
                <h2>Tabla 1: <strong>Límites máximos permisibles de opacidad del humo para vehículos con peso HASTA 3,856 kg.</strong></h2>
                <div class="box">
                  <table>
                      <tr>
                        <th>Año -  <br> modelo vehicular</th>
                        <th>Coeficiente de absorción de luz <br> (m-1)</th>
                        <th>Opacidad <br> (%)</th>
                      </tr>
                      <tr>
                         <td>2003 y Anteriores</td>
                         <td>2.00</td>
                         <td>57.68</td>
                      </tr>
                      <tr>
                         <td>2004 y Posteriores</td>
                         <td>1.50</td>
                         <td>47.53</td>
                      </tr>
                  </table>
              </div>
              <h2>Tabla 2: <strong>Límites máximos permisibles de opacidad del humo para vehículos con peso MAYOR a 3,856 kg.</strong></h2>
                <div class="box">
                  <table>
                      <tr>
                        <th>Año -  <br> modelo vehicular</th>
                        <th>Coeficiente de absorción de luz <br> (m-1)</th>
                        <th>Opacidad <br> (%)</th>
                      </tr>
                      <tr>
                         <td>1997 y Anteriores</td>
                         <td>2.25</td>
                         <td>61.99</td>
                      </tr>
                      <tr>
                         <td>1998 y Posteriores</td>
                         <td>1.50</td>
                         <td>47.53</td>
                      </tr>
                  </table>
              </div>
          </div>
          <div class="tip">
              <div class="header">
                  <img src="@/assets/images/tips/num3.svg" alt="">
                  <div>VEHICULOS A GAS NATURAL, GAS LP y COMBUSTIBLES ALTERNOS | NOM 050 SEMARNAT 2018</div>
              </div>
              <h2>Tabla 1: <strong>  Límites Máximos de Emisión del Método Dinámico</strong></h2>
              <div class="box">
                  <table>
                      <tr>
                        <th>Año -  <br> modelo vehicular</th>
                        <th>Hidrocarburos <br> (HC hppm)</th>
                        <th>Monóxido de Carbono <br> (CO % vol.)</th>
                        <th>Oxígeno <br> (O2 % vol.)</th>
                        <th>Óxidos de Nitrógeno <br> (NOx ppm)</th>
                        <th>Dilución <br>(CO + CO2 % vol.)</th>
                        <th>Factor Lambda Máx.</th>
                      </tr>
                      <tr>
                         <td>1993 y Anteriores</td>
                         <td>200</td>
                         <td>1.0</td>
                         <td>2.0</td>
                         <td>1,000</td>
                         <td>Min. 7 a 14.3 máx.</td>
                         <td>1.05</td>
                      </tr>
                      <tr>
                         <td>1994 y Posteriores</td>
                         <td>100</td>
                         <td>1.0</td>
                         <td>2.0</td>
                         <td>1,000</td>
                         <td>Min. 7 a 14.3 máx.</td>
                         <td>1.05</td>
                      </tr>
                  </table>
              </div>
            <h2>Tabla 2: <strong>  Límites Máximos de Emisión del Método Estático</strong></h2>
              <div class="box">
                  <table>
                      <tr>
                        <th>Año -  <br> modelo vehicular</th>
                        <th>Hidrocarburos <br> (HC hppm)</th>
                        <th>Monóxido de Carbono <br> (CO % vol.)</th>
                        <th>Oxígeno <br> (O2 % vol.)</th>
                        <th>Óxidos de Nitrógeno <br> (NOx ppm)</th>
                        <th>Dilución <br>(CO + CO2 % vol.)</th>
                        <th>Factor Lambda Máx.</th>
                      </tr>
                      <tr>
                         <td>1993 y Anteriores</td>
                         <td>220</td>
                         <td>1.0</td>
                         <td>2.0</td>
                         <td>No aplica</td>
                         <td>Min. 7 a 14.3 máx.</td>
                         <td>1.05</td>
                      </tr>
                      <tr>
                         <td>1994 y Posteriores</td>
                         <td>150</td>
                         <td>1.0</td>
                         <td>2.0</td>
                         <td>No aplica</td>
                         <td>Min. 7 a 14.3 máx.</td>
                         <td>1.05</td>
                      </tr>
                  </table>
              </div>
          </div>
          <div class="tip">
              <div class="header">
                  <img src="@/assets/images/tips/num4.svg" alt="">
                  <div>NOM 167 SEMARNAT 2017</div>
              </div>
              <h2> <strong> Esta norma concatena y vigila los límites de las anteriores normas citadas</strong></h2>
              <div class="box">
                  <table>
                      <tr>
                        <th>Año -  <br> modelo vehicular</th>
                        <th>Hidrocarburos <br> (HC hppm)</th>
                        <th>Monóxido de Carbono <br> (CO % vol.)</th>
                        <th>Oxígeno <br> (O2 % vol.)</th>
                        <th>Óxidos de Nitrógeno <br> (NOx ppm)</th>
                        <th>Dilución <br>(CO + CO2 % vol.)</th>
                        <th>Factor Lambda Máx.</th>
                      </tr>
                      <tr>
                         <td>2006 y posterior</td>
                         <td>80</td>
                         <td>0.4</td>
                         <td>0.4</td>
                         <td>250</td>
                         <td>Min. 7 a 14.3 máx.</td>
                         <td>1.03</td>
                      </tr>

                  </table>
                  <p>Notas de equivalencias: ppmh, partes por millón referido al hexano.</p>
                  <p>(1) Los óxidos de nitrógeno que se señalan en la presente Tabla no aplicarán en la prueba estática.</p>
                  <p>* Valores aplicados para vehículos automotores a gas natural de fábrica.</p>
              </div>
          </div>
          <!-- change banner image to 5 -->
          <div class="tip">
              <div class="header">
                  <img src="@/assets/images/tips/num5.svg" alt="">
                  <div>Sistema de diagnostico a bordo (SDB) </div>
              </div>
              <h2>Criterios de aprobación para la evaluación mediante el método de prueba a través del Sistema de Diagnóstico a Bordo</h2>
              <div class="box">
                  <table>
                      <tr>
                          <th>Criterio</th>
                          <th>Aprobado</th>
                      </tr>
                      <tr>
                          <td>Conexión con el Sistema de Diagnóstico a Bordo</td>
                          <td>Se logra comunicación con la ECU del vehículo automotor.</td>
                      </tr>
                      <tr>
                          <td>Verificación de códigos de falla</td>
                          <td>Si no existen Códigos de Falla confirmados del tren motriz en el SDB asociados a los monitores señalados en el numeral 4.1.1</td>
                      </tr>
                      <tr>
                          <td>Monitores del SDB</td>
                          <td>Si todos los monitores, por tipo de SDB, señalados en el numeral 4.1.1 están completados.</td>
                      </tr>
                  </table>

              </div>

          </div>
    
          <!-- <p>
            VVMóvil, somos una empresa comprometida con el medio ambiente, unimos nuestros conocimientos y esfuerzos para promover activamente entre la población una cultura ambiental reflexiva, que impulse acciones enfocadas a la protección del medio ambiente, el control y prevención de la contaminación del aire. 
          </p>
          <p>
           VVMóvil no persigue excluir o sustituir los centros de verificación fijos, nuestro propósito es concientizar a la población de la importancia de que su vehículo cumpla con los estándares establecidos y para ello nosotros brindamos una opción práctica, flexible y segura, que ayudará a cada habitante y Gobierno de nuestro país a preservar lo más importante, nuestra calidad de vida, salud y bienestar común. 
          </p>

          <router-link to="/contacto" ><button class="router-btn">Contacto</button></router-link> -->
      </div>
  </div>
</template>

<script>
export default {
  name: 'Nosotros',
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content{
    text-align: left;
    margin-bottom: 150px;
    .tip{
        margin-bottom:60px;
        .header{
            background-color: #0688aa;
            img{
                height: 80px;
                position: relative;
                left: -30px;
            }
            height: 50px;
            display: flex;
            align-items: center;
            color: white;
            font-size: 1.2rem;
            border-radius: 10px;
            @media screen and (max-width: 440px)  {
                height: auto;
                img{
                    left: 0;
                }
            }
        }
        h2 {
            margin-top:30px;
        }
        .box{
            margin-top: 30px;
            overflow-x: auto;
            td {
                text-align: center;
                padding: 8px;
                box-shadow: 0px 2px 3px 0px rgba($color: #000000, $alpha: 0.2);
                // border: 1px solid black;
                // border-bottom: 1px solid black;
            }
            th {
                text-align: center;
                padding: 8px;
                background-color: lightgray;
                box-shadow: 0px 2px 3px 0px rgba($color: #000000, $alpha: 0.2);
            }
            tr:nth-child(even){background-color: #f2f2f2}
        }
    }
    .legal{
        font-size: 0.7rem;
        margin-top: 70px;
        p{
            margin-top: 2px;
        }
    }
}
</style>
